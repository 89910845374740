import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import AuthProvider from "components/CustomComponents/authProviderContext";
import { ZoomProvider } from "components/CustomComponents/zoomContext";
import SnackbarProvider from "components/CustomComponents/snackbarContext";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <AuthProvider>
        <ZoomProvider>
          <SnackbarProvider>
            <App />
          </SnackbarProvider>
        </ZoomProvider>
      </AuthProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>
);
