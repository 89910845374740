import React, { useState, useContext, createContext } from "react";
import PropTypes from "prop-types";
import { Alert, Snackbar } from "@mui/material";

// Create a context for the snackbar
const SnackbarContext = createContext();

export const useSnackbar = () => useContext(SnackbarContext);

const SnackbarProvider = ({ children }) => {
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarAlertColor, setSnackbarAlertColor] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const errorAlert = (error) => {
    let errorMessage = error;
    if (error.response && error.response.data) errorMessage = error.response.data.message;
    else if (error.message) errorMessage = error.message;
    else if (typeof errorMessage !== "string") {
      errorMessage = "Error!";
      console.error(error);
    }
    if (errorMessage === "jwt expired" || error?.response?.status === 401) {
      errorMessage = "Session Expired. Please refresh the page and try again.";
    }
    setSnackbarAlertColor("error");
    setSnackbarMessage(errorMessage);
    setSnackbarOpen(true);
  };

  const errorAlertForBufferResponse = (error) => {
    let errorMessage = error;
    if (error.response && error.response.data) {
      const x = JSON.parse(String.fromCharCode(...new Uint8Array(error.response.data)));
      if (x.message) errorMessage = x.message;
      else errorMessage = "Error: Failed to download the file!";
    } else if (error.message) {
      errorMessage = error.message;
    } else if (typeof errorMessage !== "string") {
      errorMessage = "Error!";
      console.error(error);
    }

    if (errorMessage === "jwt expired") {
      errorMessage = "Session Expired. Please refresh the page and try again.";
    }

    setSnackbarAlertColor("error");
    setSnackbarMessage(errorMessage);
    setSnackbarOpen(true);
  };

  const customAlert = (message, severity = "info") => {
    if (message === "jwt expired") {
      message = "Session Expired. Please refresh the page and try again.";
    }
    setSnackbarAlertColor(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const alertClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ errorAlert, errorAlertForBufferResponse, customAlert, alertClose }}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{
          // bottom: "48px !important",
          zIndex: 4999,
        }}
        open={snackbarOpen}
        autoHideDuration={8000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          // sx={{ width: "100%", bottom: "64px !important" }}
          variant="filled"
          severity={snackbarAlertColor}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
