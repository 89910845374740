import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

// Create ZoomContext
const ZoomContext = createContext();

// ZoomProvider component to wrap the app
export const ZoomProvider = ({ children }) => {
  // Initialize zoom from cookies or set default value of 1
  const [zoom, setZoom] = useState(() => parseFloat(Cookies.get("zoomScale")) || 1);

  // Update the cookie whenever zoom changes
  useEffect(() => {
    Cookies.set("zoomScale", zoom, {
      expires: 365, // Cookie expiration: 1 year
      secure: true,
      sameSite: "strict",
    });
  }, [zoom]);

  return <ZoomContext.Provider value={{ zoom, setZoom }}>{children}</ZoomContext.Provider>;
};

// Custom hook to use the ZoomContext
export const useZoom = () => useContext(ZoomContext);

ZoomProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
