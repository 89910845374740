import axios from "axios";

export const configureApiClient = (authContext) => {
  // Create an Axios instance
  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: { "Content-Type": "application/json" },
  });

  // Add a response interceptor
  apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      // Handle the error
      const originalRequest = error.config;

      // Check if the error status is 401 (Unauthorized)
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          // Try to refresh the token
          const res = await axios.post(
            `${process.env.REACT_APP_API}/auth/refresh-token`,
            {},
            {
              withCredentials: true,
              headers: { "Content-Type": "application/json" },
            }
          );
          if (res.data.isSuccessful) {
            localStorage.setItem("access_token", res.data.accessToken);

            // Update the access token in the original request
            originalRequest.headers["Authorization"] = `Bearer ${res.data.accessToken}`;

            // reload accessToken in authContext
            if (typeof authContext?.reloadAccessToken === "function") {
              authContext.reloadAccessToken();
            } else window.location.reload();
            // Retry the original request with the new token
            return apiClient(originalRequest);
          } else {
            throw new Error(res.data.message);
          }
        } catch (refreshError) {
          // Handle refresh token error
          console.error("Refresh token error", refreshError);
          // will redirect to the login page and logout the user if the token has expired
          window.location.reload();
        }
      }

      // Handle other errors
      return Promise.reject(error);
    }
  );

  return apiClient;
};
